import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _23e9c992 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _a16716a8 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _e6666954 = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _2dca092d = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _44b4beb8 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _caf4a5b0 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f7cf9adc = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _23a2b745 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _7d03f44e = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _1ac0e605 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _71902763 = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _43caad7e = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _31c44a16 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _7b07d84c = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _3953ca5b = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _7bdd9e40 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _5e43ebde = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _23e9c992,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _a16716a8,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _e6666954,
    name: "callback"
  }, {
    path: "/downloads",
    component: _2dca092d,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _44b4beb8,
    name: "guests-details"
  }, {
    path: "/login",
    component: _caf4a5b0,
    name: "login"
  }, {
    path: "/maintenance",
    component: _f7cf9adc,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _23a2b745,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _7d03f44e,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _1ac0e605,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _71902763,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _43caad7e,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _31c44a16,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _7b07d84c,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _3953ca5b,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _7bdd9e40,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _5e43ebde,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
